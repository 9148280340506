import React from "react";

const ServiceIconThree = () => {
  return (
    <>
      <svg
        width="52"
        height="48"
        viewBox="0 0 52 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51 6.62001C51 4.63217 49.0236 2.80248 45.7074 1.34903C42.7708 0.0619693 39.75 2.51698 39.75 5.72523V13.7748M51 6.62001C51 9.61027 46.5278 12.2426 39.75 13.7748M51 6.62001V14.4762M1 6.62001C1 4.63217 2.97638 2.80248 6.29262 1.34903C9.22923 0.0619696 12.25 2.51698 12.25 5.72523V13.7748M1 6.62001V38.4345C1 43.1651 12.1929 47 26 47C39.8071 47 51 43.1651 51 38.4345V24.4835M1 6.62001C1 9.61027 5.47222 12.2426 12.25 13.7748M12.25 13.7748C16.1947 14.6665 20.9205 15.1856 26 15.1856C31.0795 15.1856 35.8053 14.6665 39.75 13.7748"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M44.7539 24.4847C44.7539 26.5573 43.075 28.2374 41.0039 28.2374C38.9328 28.2374 37.2539 26.5573 37.2539 24.4847C37.2539 22.4121 38.9328 20.7319 41.0039 20.7319C43.075 20.7319 44.7539 22.4121 44.7539 24.4847Z"
          stroke="currentcolor"
          strokeWidth="1.5"
        />
        <path
          d="M48.4961 41.9963L42.257 36.621C40.245 34.8875 37.2487 34.7149 35.034 36.2048L34.4569 36.5931C32.9179 37.6285 30.8241 37.4549 29.4939 36.1816L21.1912 28.2335C19.5341 26.6471 16.8758 26.5624 15.1121 28.0397L11.7219 30.8794L2.24609 39.7535"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default ServiceIconThree;
