import React from "react";

const ServiceIconTwo = () => {
  return (
    <>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0303 22.0693C14.7374 21.7764 14.2626 21.7764 13.9697 22.0693C13.6768 22.3622 13.6768 22.837 13.9697 23.1299L15.0303 22.0693ZM20.6908 28.7904L21.2211 28.26V28.26L20.6908 28.7904ZM24.5091 28.7904L25.0394 29.3207L24.5091 28.7904ZM28.7907 24.5088L28.2604 23.9785L28.2604 23.9785L28.7907 24.5088ZM32.6091 24.5088L33.1394 23.9785V23.9785L32.6091 24.5088ZM41.4998 33.3995V34.1495C41.914 34.1495 42.2498 33.8137 42.2498 33.3995H41.4998ZM42.2498 26.6496C42.2498 26.2354 41.914 25.8996 41.4998 25.8996C41.0856 25.8996 40.7498 26.2354 40.7498 26.6496H42.2498ZM34.7498 32.6495C34.3356 32.6495 33.9998 32.9853 33.9998 33.3995C33.9998 33.8137 34.3356 34.1495 34.7498 34.1495V32.6495ZM13.9697 23.1299L20.1604 29.3207L21.2211 28.26L15.0303 22.0693L13.9697 23.1299ZM25.0394 29.3207L29.321 25.0391L28.2604 23.9785L23.9788 28.26L25.0394 29.3207ZM32.0787 25.0391L40.9695 33.9299L42.0301 32.8692L33.1394 23.9785L32.0787 25.0391ZM42.2498 33.3995V26.6496H40.7498V33.3995H42.2498ZM41.4998 32.6495H34.7498V34.1495H41.4998V32.6495ZM29.321 25.0391C30.0825 24.2776 31.3172 24.2776 32.0787 25.0391L33.1394 23.9785C31.7921 22.6312 29.6077 22.6312 28.2604 23.9785L29.321 25.0391ZM20.1604 29.3207C21.5077 30.668 23.6921 30.668 25.0394 29.3207L23.9788 28.26C23.2173 29.0216 21.9826 29.0216 21.2211 28.26L20.1604 29.3207Z"
          fill="CurrentColor"
        />
        <circle
          cx="46.9007"
          cy="9.09994"
          r="8.09994"
          stroke="CurrentColor"
          strokeWidth="1.5"
        />
        <path
          d="M54.9996 23.9498V27.9998C54.9996 40.7276 54.9996 47.0915 51.0456 51.0456C47.0915 54.9996 40.7276 54.9996 27.9998 54.9996C15.272 54.9996 8.90806 54.9996 4.95403 51.0456C1 47.0915 1 40.7276 1 27.9998C1 24.9533 1 22.2714 1.05422 19.8999M32.0498 1H27.9998C15.272 1 8.90806 1 4.95403 4.95403C3.76357 6.14449 2.93152 7.5534 2.34999 9.28867"
          stroke="CurrentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default ServiceIconTwo;
