import React from "react";

const ServiceIconFive = () => {
  return (
    <>
      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5987 29.5987V37.3987H37.3987V16.5986H16.5987V19.1986"
          stroke="CurrentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M37.3987 37.4014L45.1988 37.4001C49.5066 37.3995 52.9993 40.8911 53 45.1989C53.0007 49.5068 49.509 52.9995 45.2012 53.0002C40.8934 53.0009 37.4007 49.5092 37.4 45.2014L37.3987 37.4014Z"
          stroke="CurrentColor"
          strokeWidth="1.5"
        />
        <path
          d="M16.6013 37.4014L8.80124 37.4001C4.49341 37.3995 1.00068 40.8911 1 45.1989C0.999321 49.5068 4.49096 52.9995 8.79878 53.0002C13.1066 53.0009 16.5993 49.5092 16.6 45.2014L16.6013 37.4014Z"
          stroke="CurrentColor"
          strokeWidth="1.5"
        />
        <path
          d="M37.3987 16.5988L45.1988 16.6C49.5066 16.6007 52.9993 13.1091 53 8.80124C53.0007 4.49341 49.509 1.00068 45.2012 1C40.8934 0.999321 37.4007 4.49096 37.4 8.79878L37.3987 16.5988Z"
          stroke="CurrentColor"
          strokeWidth="1.5"
        />
        <path
          d="M16.6013 16.5988L8.80124 16.6C4.49341 16.6007 1.00068 13.1091 1 8.80124C0.999321 4.49341 4.49096 1.00068 8.79878 1C13.1066 0.999321 16.5993 4.49096 16.6 8.79878L16.6013 16.5988Z"
          stroke="CurrentColor"
          strokeWidth="1.5"
        />
      </svg>
    </>
  );
};

export default ServiceIconFive;
